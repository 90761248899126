<template>
    <div class="CalibrationFreq full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <v-dialog ref="pathdialog" v-model="pathmodal" :return-value.sync="exportPath" persistent width="500px">
                <div>
                    <div class="dialogcontainer pa-8">
                        <p style="font-weight: 500">{{ $t('report.selectPathExport') }}</p>
                        <v-list>
                            <v-list-item-group
                                v-model="exportPath"
                                color="disiIrisblue"
                                class="list mt-4 mb-8"
                                id="pathmodal-list"
                                mandatory
                            >
                                <v-list-item
                                    v-for="(item) in this.pathlist"
                                    :key="item.id"
                                    :value="item.id"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item.name"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                        <div class="button-container d-flex justify-space-between">
                            <v-btn elevation="0" class="content-btn small" @click="pathmodal = false;">{{ $t('footerButton.cancel') }}</v-btn>
                            <v-btn elevation="0" class="content-btn small" :disabled="exportPath == ''" @click="$refs.pathdialog.save(exportPath); doExport()">{{ $t('footerButton.save') }}</v-btn>
                        </div>
                    </div>
                </div>
            </v-dialog>
            <navigation-left-small noBackButton disabled/>
            <div class="content-area pa-5 flex-grow-1 bg-white">
<!--                Start // Counter -->
                <div v-if="screen === 'start' || screen === 'counter'">
                    <p class="pa-3"> {{ $t('adjCal.securityInfo') }}</p>
                    <p class="pa-3"> {{ $t('adjCal.calibrationFreqStartTitle') }}</p>
                    <v-row class="d-flex ">
                        <v-col cols="6" class="pa-0 pt-5">
                            <v-row class="d-flex align-center">
                                <v-col class="col-4">
                                    <p>{{ $t('adjCal.counter') }}</p>
                                </v-col>
                                <v-col class="col-4">
                                    <text-input inputName="calibrationCounter-counter" readonly/>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
<!--                Time-->
                <div v-if="screen === 'time'">
                    <p class="pa-3"> {{ $t('adjCal.calibrationFreqTimeTitle') }}</p>
                    <v-form ref="form">
                        <v-row class="d-flex ">
                            <v-col cols="6" class="pa-0 pt-0">
                                <v-row class="d-flex align-center">
                                    <v-col class="col-4">
                                        <p>{{ $t('adjCal.time') }}</p>
                                    </v-col>
                                    <v-col class="col-4">
                                        <text-input inputName="calibrationFreqReport-measuredTime" @onInputFocus="onInputFocus" onlyNumbers suffix="sec" :rules="[v => !!v || $t('validation.required'), rules.measuredTime]"/>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                </div>
<!--                Finish-->
                <div v-if="screen === 'finish'">
                    <h2 class="pa-3 pb-5 successMessage" v-if="calibrationFreqReport.success">{{ $t('adjCal.calibrationSuccessful') }}</h2>
                    <h2 class="pa-3 pb-5 successMessage" v-else>{{ $t('adjCal.calibrationFailed') }}</h2>
                    <v-row class="d-flex">
                        <v-col cols="6" class="pa-0 pr-4">
                            <v-row class="d-flex align-center">
                                <v-col class="col-4">
                                    <p>{{ $t('adjCal.reference') }}</p>
                                </v-col>
                                <v-col class="col-6">
                                    <text-input class="noAction" inputName="calibrationFreqReport-reference" readonly suffix="s/min"/>
                                </v-col>
                            </v-row>
                            <v-row class="d-flex align-center">
                                <v-col class="col-4">
                                    <p>{{ $t('adjCal.measured') }}</p>
                                </v-col>
                                <v-col class="col-6">
                                    <text-input class="noAction" inputName="calibrationFreqReport-measuredTime" readonly suffix="s/min" :class="{causedError: checkTolerance(calibrationFreqReport.measuredTime)}"/>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="6" class="pa-0 pl-4">
                            <v-row class="d-flex align-center">
                                <v-col class="col-4">
                                    <p>{{ $t('adjCal.tolerance') }}</p>
                                </v-col>
                                <v-col class="col-6">
                                    <text-input class="noAction" inputName="calibrationFreqReport-tolerance" readonly suffix="s/min"/>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
<!--            4-->
            <footer-button v-if="screen != 'finish'" ></footer-button>
            <footer-button v-if="screen === 'finish'" buttonFunction="export" :successCheck="showExportSuccess" :progress="showExportProgress" @footerButtonClick="pathmodal = true" :class="{'disabled': !checkUserRights('adjCalExport')}"></footer-button>
<!--            5-->
            <footer-button v-if="screen != 'finish'" buttonFunction="cancel" @footerButtonClick="goToCalibration" ></footer-button>
            <footer-button v-if="screen === 'finish'" buttonFunction="print" :successCheck="showPrintSuccess" :progress="showPrintProgress" @footerButtonClick="printReport" :class="{'disabled': !checkUserRights('adjCalPrint')}"></footer-button>
<!--            6-->
            <footer-button v-if="screen === 'start'" buttonFunction="start" @footerButtonClick="startCalibration" ></footer-button>
            <footer-button v-if="screen === 'counter'" buttonFunction="start" @footerButtonClick="startCalibration" class="disabled" ></footer-button>
            <footer-button v-if="screen === 'time'" buttonFunction="ok" @footerButtonClick="goOn" :class="{'disabled': calibrationFreqReport.measuredTime === '' || calibrationFreqReport.measuredTime === null}" ></footer-button>
            <footer-button v-if="screen === 'finish'" buttonFunction="ok" @footerButtonClick="goToCalibration"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import FooterButton from '@/components/FooterButton.vue'
import {mapState} from "vuex";
import TextInput from "@/components/TextInput.vue";
import NavigationLeftSmall from "@/components/NavigationLeftSmall.vue";
import mixins from "@/mixins/mixins";

export default {
    name: 'CalibrationFreq',
    components: {
        NavigationLeftSmall,
        TextInput,
        FooterButton,
    },
    props: {
    },
    data () {
        return{
            screen: 'start',
            counter: false,
            calibrationId: 0,
            showPrintProgress: false,
            showPrintSuccess: false,
            showExportProgress: false,
            showExportSuccess: false,
            pathmodal: false,
            exportPath: '',
            rules: {
                measuredTime: v => {
                    if (v) {
                        return parseFloat(v) <= 120 || `${this.$t('validation.maximum')} 120s/min.`;
                    } else return true;
                },
            }
        }
    },
    computed: {
        ...mapState([
            'calibrationFreqReport',
            'calibrationCounter',
            'activeStationId',
            'pathlist'
        ]),
    },
    methods:{
        goToCalibration(){
            this.$store.commit('resetCalibrationFrequency');
            this.$emit("resetInput");
            this.counter = false;
            this.$router.push('calibration');
        },
        startCalibration(){
            this.$store.dispatch('postAxiosNoSetter', ['/disi/calibration/calibrateFrequency/start', {id: this.activeStationId}])
                .then(response =>{
                    if (response.status === 200) {
                        this.screen = 'counter';
                        this.counter = true;
                        this.calibrationId = response.data.calibrationId;
                        this.getCounter();
                    }
                })
        },
        getCounter(){
            this.$store.dispatch('postAxiosSetter', ['/disi/calibration/calibrateFrequency/counter', {calibrationId: this.calibrationId}, 'calibrationCounter'])
                .then(response =>{
                    if (response.status === 200) {
                        if (this.counter == true && this.$store.state.calibrationCounter.counter < 32){
                            setTimeout(()=>{
                                return this.getCounter();
                            }, 200);
                        } else {
                            this.$store.dispatch('postAxiosNoSetter', ['/disi/calibration/calibrateFrequency/stopCounter', {calibrationId: this.calibrationId}]);
                            this.screen = 'time';
                            this.counter = false;
                        }
                    } else {
                        this.counter = false;
                    }

                })
        },
        goOn(){
            this.$refs.form.validate();
            if (this.$refs.form.validate()) {
                this.$store.dispatch('postAxiosSetter', ['/disi/calibration/calibrateFrequency/measuredTime', {
                    calibrationId: this.calibrationId,
                    time: parseFloat(this.calibrationFreqReport.measuredTime)},
                    'calibrationFreqReport'])
                    .then(response =>{
                        if (response.status === 200) {
                            this.screen = 'finish';
                        }
                    })
            }
        },
        checkTolerance(value){
            let tolerance = this.calibrationFreqReport.tolerance;
            tolerance = tolerance.split('-');
            let maxTolerance = this.calibrationFreqReport.reference + parseFloat(tolerance[1]);
            let minTolerance = this.calibrationFreqReport.reference - parseFloat(tolerance[1]);
            if (value > maxTolerance || value < minTolerance){
                return true;
            } else {
                return false;
            }
        },
        doExport(){
            this.showExportProgress = true;
            this.$store.dispatch('postAxiosNoSetter', ['/disi/calibration/export', {'calibrationId': [this.calibrationId], 'pathId': this.exportPath}])
                .then(response =>{
                    this.showExportProgress = false;
                    if (response.status === 200) {
                        this.showExportSuccess = true;
                        setTimeout(()=>{
                            this.showExportSuccess = false;
                        }, 800)
                    }
                })
        },
        printReport(){
            this.showPrintProgress = true;
            this.$store.dispatch('postAxiosNoSetter', ['/disi/calibration/print', {'calibrationId': [this.calibrationId]}])
                .then(response =>{
                    this.showPrintProgress = false;
                    if (response.status === 200) {
                        this.showPrintSuccess = true;
                        setTimeout(()=>{
                            this.showPrintSuccess = false;
                        }, 800)
                    }
                })
        },
        // Keyboard functions ------------------ //
        onInputFocus(input, onlyNumbers) {
            this.$emit('onInputFocus', [input.target.id, onlyNumbers])
            this.$vuetify.goTo('#'+input.target.id, {duration: 100, offset: 80, container: ".content-area"});
        },
    },
    mixins: [mixins],
    beforeRouteLeave (to, from, next) {
        this.$store.commit('resetCalibrationFrequency');
        this.$emit("resetInput");
        if(this.screen === 'start' || this.screen === 'finish'){
            next()
        } else {
            this.$confirm({message: this.$t('alerts.cancelCalibration'), show: true, cancelText: this.$t('general.cancel'), continueText: this.$t('general.continue') })
                .then(() => {
                    // continue
                    this.$store.dispatch('postAxiosNoSetter', ['/disi/calibration/calibrate/cancel', {'calibrationId': this.calibrationId}])
                    this.counter = false;
                    next();
                })
                .catch(() => {
                    // cancel
                });
        }
    },
}
</script>
<style scoped lang="scss">
</style>
